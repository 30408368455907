@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Open+Sans:ital,wght@0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,600;0,700;1,400&family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Oleo+Script&family=Open+Sans:ital,wght@0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,600;0,700;1,400&family=Rubik:wght@400;500;700&display=swap');
.app{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    position: relative;
    /* font-family: 'Kanit', sans-serif; */
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Rubik', sans-serif; */
    /* font-family: 'Oleo Script', cursive; */

}
.scroll {
    display: flex;
    justify-content: left;
    padding: 5px;
    width: 100%;
    overflow: hidden;
    gap: 10px;
    background: linear-gradient(toight, rgba(255, 0, 0, 0.4),rgba(255, 0, 0, 0.8));

}
.box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e1e1e1;
    padding: 5px;
    border-radius: 40px;
    
}

form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 20px;
    border-radius: 40px;
}
form div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

  